<template>
    <div class="overview-page">
        <v-row>
            <v-col class="d-flex" cols="12" lg="4" md="6" xl="4">
                <v-card class="d-flex flex-column" elevation="6">
                    <img src="@/assets/images/image-attention-heatmap.png" style="width:100%;height:auto">
                    <v-card-title class="primary white--text text-xl-h3 text-lg-h4 text-md-h4 text-sm-h5 my-4">AI
                        Vision
                    </v-card-title>
                    <v-card-text>
                        <p class="card-text text-justify">Visually, our eye is immediately attracted to look at
                            particular things. Whether it be bold colors, a human face, or distinct shapes, there’s an
                            element to vision that is biological and instant.</p>
                        <p class="card-text text-justify">Using our AI model – trained with years of human eye tracking
                            experiments – we can predict where people who see your designs are most likely to look. In a
                            matter of moments, the Design Optics tool creates heat maps you can use to optimize the
                            efficacy of your work.</p>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modals.firstNotice=true">
                            <v-icon>mdi-dots-vertical</v-icon>
                            Instructions
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col class="d-flex" cols="12" lg="4" md="6" xl="4">
                <v-card class="d-flex flex-column" elevation="6">
                    <img src="@/assets/images/image-attention-aoi.png" style="width:100%;height:auto">
                    <v-card-title class="primary white--text my-4 text-xl-h3 text-lg-h4 text-md-h4 text-sm-h5">Areas of
                        Interest
                    </v-card-title>
                    <v-card-text>
                        <p class="card-text text-justify">In any design, there will be particular elements that you are
                            really hoping will get the viewer’s attention. They might be a picture of your product, a
                            specific message, or maybe your branding.</p>
                        <p class="card-text text-justify">With the Areas of Interest analysis, you can highlight
                            different areas of your design, and the AI will tell you what percentage of people will
                            notice that area.</p>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modals.secondNotice=true">
                            <v-icon>mdi-dots-vertical</v-icon>
                            Instructions
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col class="d-flex" cols="12" lg="4" md="6" xl="4">
                <v-card class="d-flex flex-column" elevation="6">
                    <img src="@/assets/images/img-72.png" style="width:100%;height:auto">
                    <v-card-title class="primary white--text text-xl-h3 text-lg-h4 text-md-h4 text-sm-h5 my-4">ABC
                        Score
                    </v-card-title>
                    <v-card-text>
                        <p class="card-text text-justify">The ABC score gives you a single number to understand the
                            performance of your creative design.</p>
                        <p class="card-text text-justify">It gives you one combined metric that tells you how good a job
                            your design is doing in terms of being aesthetically appealing, having people see your
                            branding cues, and having people see your communication messages.</p>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modals.thirdNotice = true">
                            <v-icon>mdi-dots-vertical</v-icon>
                            Instructions
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="modals.firstNotice" max-width="800" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Where To Start From?
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <strong>1. Create Project</strong>
                            <p>To do the AI Vision analysis, start a new project with the button at the top of the page,
                                then go through the steps guided by the wizard and upload whichever images you want to
                                test.</p>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-divider class="mx-2" vertical></v-divider>
                            <v-img alt="Thumbnail Image" contain src="@/assets/images/instruction_1.png"></v-img>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-img alt="Thumbnail Image" src="@/assets/images/instruction_2.png"></v-img>
                            <v-divider class="mx-2" vertical></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <strong>2. See the results</strong>
                            <p>From the project page, you can visit each image to see the results under the &quot;AI
                                Vision&quot; tab.</p>
                        </v-col>
                        <v-col cols="12">
                        </v-col>
                    </v-row>
                    <v-card-subtitle><strong>
                        If you have more questions, don't hesitate to contact us or send us an
                        email <a href="mailto:support@designoptics.ai">support@designoptics.ai</a>. We're
                        here to help!</strong></v-card-subtitle>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn color="primary" @click="modals.firstNotice=false">Sounds good!</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modals.secondNotice" max-width="800" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Areas of interst.
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <strong>1. Open Project</strong>
                            <p>After you have done the basic AI Vision analysis, you can create your areas of interest.
                                Within your project, click "See details" on one of your images to enter its page.Once
                                you are on the page for that image, choose the
                                <v-icon>mdi-group</v-icon>
                                "Areas of Interest" tab.
                            </p>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-divider class="mx-2" vertical></v-divider>
                            <v-img alt="Thumbnail Image" contain src="@/assets/images/instruction_3.png"></v-img>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-img alt="Thumbnail Image" src="@/assets/images/instruction_4.png"></v-img>
                            <v-divider class="mx-2" vertical></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <strong>2. Create your first AOI Analysis</strong>
                            <p>Click the "+" button to start a new "Areas of Interest" analysis (you can have more than
                                one). Using
                                <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                                button, draw boxes around whichever areas you want to measure. Use
                                <v-icon>mdi-arrow-all</v-icon>
                                button to move around and resize areas, and this
                                <v-icon>mdi-eraser</v-icon>
                                button to remove areas from the image. After doing this - click "Save & process" button
                                to start the analysis.
                            </p>
                        </v-col>
                        <v-col cols="12">
                        </v-col>
                    </v-row>
                    <v-card-subtitle><strong>If you have more questions, don't hesitate to contact us or send us an
                        email <a href="mailto:support@designoptics.ai">support@designoptics.ai</a>. We're
                        here to help!</strong></v-card-subtitle>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn color="primary" @click="modals.secondNotice=false">Sounds good!</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modals.thirdNotice" max-width="800" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Areas of interst.
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <strong>1. Open Project</strong>
                            <p>After you have done the basic AI Vision analysis, you can create your ABC score analysis.
                                Within your project, click "See details" on one of your images to enter its page.Once
                                you are on the page for that image, choose the
                                <v-icon>mdi-bullseye-arrow</v-icon>
                                "ABC Score" tab.
                            </p>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-divider class="mx-2" vertical></v-divider>
                            <v-img alt="Thumbnail Image" contain src="@/assets/images/instruction_5.png"></v-img>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="6">
                            <v-img alt="Thumbnail Image" src="@/assets/images/instruction_6.png"></v-img>
                            <v-divider class="mx-2" vertical></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <strong>2. Create your first ABC Analysis</strong>
                            <p>You will now be guided through the steps to set up the score</p>
                        </v-col>
                        <v-col cols="12">
                        </v-col>
                    </v-row>
                    <v-card-subtitle><strong>If you have more questions, don't hesitate to contact us or send us an
                        email <a href="mailto:support@designoptics.ai">support@designoptics.ai</a>. We're
                        here to help!</strong></v-card-subtitle>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn color="primary" @click="modals.thirdNotice=false">Sounds good!</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
export default {
  data: () => ({
    modals: {
      firstNotice: false,
      secondNotice: false,
      thirdNotice: false,
    },
  }),
};

</script>
<style lang="scss">
.card-text {
  font-size: 16px;
  font-weight: 500;
  color: rgba(54, 54, 54, .8)
}
</style>
